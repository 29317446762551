#home {
    height: 100vh;
    width: 100%;
    background-color: #373737;
    color: whitesmoke;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#status {
    min-height: 50vh;
    width: 100%;
    background-color: #494949;
    color: whitesmoke;
    padding: 1rem;
}

.card {
    width: 22rem !important;
    height: 10rem !important;
    color: black;
    padding: 0;
    margin: 0;
}

 .card .container-fluid {
     width: 100% !important;
     height: 100% !important;
     padding: 0;
     margin: 0;
 }

.card .row {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
}

.card .imgCon {
    width: 40%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.card .textCon {
    width: 60%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.card .img {
    width: 100%;
    height: 100%;
}